import React from 'react'
import { Box, Grid, Flex } from "theme-ui"
import { Canvas} from 'react-three-fiber'
import Layout from "../components/layout"
import * as THREE from 'three';
import { Geometry } from 'three';




function Logo(){

//create scene
// var scene = new THREE.Scene();
// //create windows
// var camera = new THREE.PerspectiveCamera( 75, window.innerWidth / window.innerHeight, 0.1, 1000 );
// //load font
// const loader = new THREE.FontLoader();
// // //to render camera and scene
// var renderer = new THREE.WebGLRenderer();
// renderer.setSize( window.innerWidth, window.innerHeight );
// document.body.appendChild( renderer.domElement );
// // //set camera position
// camera.position.z = 5;
// // // set light !important if you want to see color of object
// const light = new THREE.SpotLight( 0xFFFFFF,1 );
// light.position.set(100,100,100)
// scene.add(light)

// // // set variable for typography
// const data = {
//             text : "kennyDN",
//             size : 1.2,
//             height : 0.098,
//             curveSegments : 10,
//             font : "helvetiker",
//             weight : "Regular",
//             bevelEnabled : false,
//             bevelThickness : 1,
//             bevelSize : 0.2,
//             bevelSegments: 10,
//         };
// // set material(text,line,circles, waves, letters)

    // const text = new THREE.Mesh( new THREE.Geometry(), new THREE.MeshPhongMaterial( { color: "#C3B749" } ));
//       //external circle
//     const circle_material = new THREE.Mesh(new THREE.Geometry(), new    THREE.MeshBasicMaterial({color:"black"}));
//     const circle_material_1 = new THREE.Mesh(new THREE.Geometry(), new    THREE.MeshBasicMaterial({color:"black"}));
//       //internal circle
//     const circle_material_internal = new THREE.Mesh(new THREE.Geometry(), new      THREE.MeshBasicMaterial({color:"white"}));
//     const circle_material_internal_1 = new THREE.Mesh(new THREE.Geometry(), new    THREE.MeshBasicMaterial({color:"white"}));
//       //generate action line-wave
//     const line_material_wave = new THREE.Mesh(new THREE.Geometry(), new       THREE.LineBasicMaterial( { color: "black"}) );

// //generate text function, using typeface to load font, and init material, and geometry
// function generateCircle(){
//   // generate circles
//   const geometry = new THREE.CircleBufferGeometry( 1.2, 24 );
//    geometry.computeBoundingBox();
//    //line.geometry.dispose();
//    circle_material.geometry = geometry;
//    //circle_material_1.geometry = geometry;
//    var box = new THREE.BoxHelper( circle_material, "black" );
//    circle_material.position.x =-15.7;
// }

// function generateCircle_1(){
//   // generate circles
//   const geometry = new THREE.CircleBufferGeometry( 1.2, 24 );
//    geometry.computeBoundingBox();
//    //line.geometry.dispose();
//    circle_material_1.geometry = geometry;
//    //circle_material_1.geometry = geometry;
//    var box = new THREE.BoxHelper( circle_material_1, "black" );
//    circle_material_1.position.x = -18.0;
// }

// function generateCircle_internal(){
//   // generate circles
//   const geometry = new THREE.CircleBufferGeometry( 1.1, 24 );
//    geometry.computeBoundingBox();
//    //line.geometry.dispose();
//    circle_material_internal.geometry = geometry;
//    //circle_material_1.geometry = geometry;
//    //var box = new THREE.BoxHelper( circle_material_internal, "yellow" );
//    circle_material_internal.position.x =-15.7;
// }

// function generateCircle_internal_1(){
//   // generate circles
//   const geometry = new THREE.CircleBufferGeometry( 1.1, 24 );
//    geometry.computeBoundingBox();
//    //line.geometry.dispose();
//    circle_material_internal_1.geometry = geometry;
//    //circle_material_1.geometry = geometry;
//    //var box = new THREE.BoxHelper( circle_material_internal, "yellow" );
//    circle_material_internal_1.position.x =-18.0;
// }

// function line(){
//   const points = [];
//   const material = new THREE.LineBasicMaterial( { color: 0x0000ff } );
//    //generate points
//     points.push( new THREE.Vector3( -19.1, 0, 0 ) );
//     points.push( new THREE.Vector3( 18, 0, 0 ) );
//     const geometry = new THREE.BufferGeometry().setFromPoints( points );
//     const line = new THREE.Line( geometry, material );
//     scene.add(line);
// }

// function wave(){
//   // generate multiple points
//   // waving ~ slowly
//   const points = [];
//   const material = new THREE.LineBasicMaterial( { color: "red" } );
//    //generate points
//   for (i = 0; i < 20; i++) {
//       points.push( new THREE.Vector3( i, 0, 0 ) );
//       i ++;
//     }
//     //points.push( new THREE.Vector3( 18, 0, 0 ) );
//     const geometry = new THREE.BufferGeometry().setFromPoints( points );
//     const wave_line = new THREE.Line( geometry, material );
//     wave_line.position.y =-0.2;
//     scene.add(wave_line);
//     //wave.rotation.x += 1;
  
// }
//   function generateTextGeometry() {
//       //generate kenndy dn letter
//         var loader = new THREE.FontLoader();
//             loader.load( 'https://threejs.org//examples/fonts/helvetiker_regular.typeface.json', function ( font ) {
                
//                 const geometry = new THREE.TextGeometry( data.text, {
//                     font: font,
//                     size: data.size,
//                     height: data.height,
//                     curveSegments: data.curveSegments,
//                     bevelEnabled: data.bevelEnabled,
//                     bevelThickness: data.bevelThickness,
//                     bevelSize: data.bevelSize,
//                     bevelSegments: data.bevelSegments,
//                 } );

//                 geometry.computeBoundingBox()
//                 geometry.center();
//                 text.geometry.dispose();
//                 text.geometry = geometry;
//                 text.position.x =10;
//                 text.position.y = 0.7;
//             });
//         }

// // add scene together
//       scene.add( text );
//       //scene.add(line_material);
//       scene.add(circle_material);
//       scene.add(circle_material_1);
//       scene.add(circle_material_internal);
//       scene.add(circle_material_internal_1);
//       //adding wave

// // call the function to be rendered
//       generateTextGeometry();
//       //generateLine();
//       line();
//       wave();
//       generateCircle();
//       generateCircle_1()
//       generateCircle_internal();
//       generateCircle_internal_1();
   
// var animate = function () {
// 				requestAnimationFrame( animate );
//         renderer.setClearColor( 0xffffff);
// 				renderer.render( scene, camera );
// 			};
// // call animate function to make everything move
// 			animate();
    return(
          <Layout>
    <Canvas />
  </Layout>
    )
}

export default Logo